const teamEventVarArray = [
  {
    teamEventId: 'lightningvskraken2021',
    teamEventName: 'Lightning vs. Kraken',
    teamEventDates: ['11-26-2021'],
    teamEventDatesFull: 'November 26th, 2021',
    teamEventDescription: '',
    teamEventYear: 2021,
    teamEventLocation: 'Tampa, Florida',
    teamEventDivisions: ['8U', '10U', '12UA1', '12UA2', '14U', '16U'],
    seasonYear: '2021-2022',
    paypalButtonId: 'PQDJ2DSKF6ZEY',
    paypalButtonIdDev: 'FU4EQTDYW6EX6',
    optionsTitle: 'Tickets',
    quantityLimit: 7,
    quantityAvailable: 0,
    options: [
      {
        quantity: 1,
        raw: '1+Ticket',
        description: '1 Ticket',
        total: '$72',
        totalNum: 72,
        totalNumString: '72'
      },
      {
        quantity: 2,
        raw: '2+Tickets',
        description: '2 Tickets',
        total: '$144',
        totalNum: 144,
        totalNumString: '144'
      },
      {
        quantity: 3,
        raw: '3+Tickets',
        description: '3 Tickets',
        total: '$216',
        totalNum: 216,
        totalNumString: '216'
      },
      {
        quantity: 4,
        raw: '4+Tickets',
        description: '4 Tickets',
        total: '$288',
        totalNum: 288,
        totalNumString: '288'
      },
      {
        quantity: 5,
        raw: '5+Tickets',
        description: '5 Tickets',
        total: '$360',
        totalNum: 360,
        totalNumString: '360'
      },
      {
        quantity: 6,
        raw: '6+Tickets',
        description: '6 Tickets',
        total: '$432',
        totalNum: 432,
        totalNumString: '432'
      },
      {
        quantity: 7,
        raw: '7+Tickets',
        description: '7 Tickets',
        total: '$504',
        totalNum: 504,
        totalNumString: '504'
      }
    ],
    retired: true
  },
  {
    teamEventId: 'lightningvsducks2024lower',
    teamEventName: 'Lightning vs. Ducks (Lower Seats)',
    teamEventDates: ['01-13-2024'],
    teamEventDatesFull: 'January 13th, 2024',
    teamEventDescription: '',
    teamEventYear: 2024,
    teamEventLocation: 'Tampa, Florida',
    teamEventDivisions: ['10UBlue', '12UBlack', '14UOrange'],
    seasonYear: '2023-2024',
    paypalButtonId: '5DFFL29LTX97N',
    paypalButtonIdDev: 'FYJLVHPP3B4NE',
    optionsTitle: 'Tickets',
    quantityLimit: 7,
    quantityAvailable: 47,
    options: [
      {
        quantity: 1,
        raw: '1+Ticket',
        description: '1 Ticket',
        total: '$159',
        totalNum: 159,
        totalNumString: '159'
      },
      {
        quantity: 2,
        raw: '2+Tickets',
        description: '2 Tickets',
        total: '$318',
        totalNum: 318,
        totalNumString: '318'
      },
      {
        quantity: 3,
        raw: '3+Tickets',
        description: '3 Tickets',
        total: '$477',
        totalNum: 477,
        totalNumString: '477'
      },
      {
        quantity: 4,
        raw: '4+Tickets',
        description: '4 Tickets',
        total: '$636',
        totalNum: 636,
        totalNumString: '636'
      },
      {
        quantity: 5,
        raw: '5+Tickets',
        description: '5 Tickets',
        total: '$795',
        totalNum: 795,
        totalNumString: '795'
      },
      {
        quantity: 6,
        raw: '6+Tickets',
        description: '6 Tickets',
        total: '$954',
        totalNum: 954,
        totalNumString: '954'
      },
      {
        quantity: 7,
        raw: '7+Tickets',
        description: '7 Tickets',
        total: '$1113',
        totalNum: 1113,
        totalNumString: '1113'
      }
    ],
    retired: true
  },
  {
    teamEventId: 'lightningvsducks2024upper',
    teamEventName: 'Lightning vs. Ducks (Upper Seats)',
    teamEventDates: ['01-13-2024'],
    teamEventDatesFull: 'January 13th, 2024',
    teamEventDescription: '',
    teamEventYear: 2024,
    teamEventLocation: 'Tampa, Florida',
    teamEventDivisions: ['10UBlue', '12UBlack', '14UOrange'],
    seasonYear: '2023-2024',
    paypalButtonId: 'CM7V9C5W9742G',
    paypalButtonIdDev: 'JTFZAXWH4CYU4',
    optionsTitle: 'Tickets',
    quantityLimit: 7,
    quantityAvailable: 31,
    options: [
      {
        quantity: 1,
        raw: '1+Ticket',
        description: '1 Ticket',
        total: '$58',
        totalNum: 58,
        totalNumString: '58'
      },
      {
        quantity: 2,
        raw: '2+Tickets',
        description: '2 Tickets',
        total: '$116',
        totalNum: 116,
        totalNumString: '116'
      },
      {
        quantity: 3,
        raw: '3+Tickets',
        description: '3 Tickets',
        total: '$174',
        totalNum: 174,
        totalNumString: '174'
      },
      {
        quantity: 4,
        raw: '4+Tickets',
        description: '4 Tickets',
        total: '$232',
        totalNum: 232,
        totalNumString: '232'
      },
      {
        quantity: 5,
        raw: '5+Tickets',
        description: '5 Tickets',
        total: '$290',
        totalNum: 290,
        totalNumString: '290'
      },
      {
        quantity: 6,
        raw: '6+Tickets',
        description: '6 Tickets',
        total: '$348',
        totalNum: 348,
        totalNumString: '348'
      },
      {
        quantity: 7,
        raw: '7+Tickets',
        description: '7 Tickets',
        total: '$406',
        totalNum: 406,
        totalNumString: '406'
      }
    ],
    retired: true
  },
  {
    teamEventId: 'mitesjamboree2023',
    teamEventName: 'Mites Jamboree',
    teamEventDates: ['01-16-2023'],
    teamEventDatesFull: '',
    teamEventDescription: '',
    teamEventYear: 2023,
    teamEventLocation: '',
    teamEventDivisions: ['8U1', '8U2'],
    seasonYear: '2022-2023',
    paypalButtonId: '6THG4YFAMKAS4',
    paypalButtonIdDev: 'XK6M24DMY9ESQ',
    optionsTitle: 'Tickets',
    quantityLimit: 9999,
    quantityAvailable: 9998,
    retired: true,
    total: '$259',
    totalNum: 259,
    totalNumString: '259',
    playerName2Required: false,
    playerName3Required: false,
  },
  {
    teamEventId: 'mitessolarbears2023',
    teamEventName: 'Mites Solar Bears Game',
    teamEventDates: ['01-21-2023'],
    teamEventDatesFull: 'January 21st, 2023',
    teamEventDescription: 'Registration includes 3 tickets: one for each player and two additional tickets. Contact staff if additional tickets are needed.',
    teamEventYear: 2023,
    teamEventLocation: '',
    teamEventDivisions: ['8U2'],
    seasonYear: '2022-2023',
    paypalButtonId: '8ZM24MZBMAPD4',
    paypalButtonIdDev: 'YYJ8Z5CLZ5KPY',
    optionsTitle: 'Tickets',
    quantityLimit: 9999,
    quantityAvailable: 9998,
    retired: true,
    total: '$77',
    totalNum: 77,
    totalNumString: '77',
    playerName2Required: false,
    playerName3Required: false,
  },
  {
    teamEventId: 'teampictures2324',
    teamEventName: 'Team Pictures 2023-2024',
    teamEventDates: ['11-30-2023'],
    teamEventDatesFull: 'November 30th, 2023',
    teamEventDescription: 'Includes all individual pictures taken, as well as the team picture. \n' +
      '\n' +
      'All release will be provided so that they can be printed anywhere you would like. \n' +
      '\n' +
      'Examples of locations include Costco, Sams club, canvaschamps.com. \n' +
      '\n' +
      'Please use email that you would like used to receive the pictures. \n' +
      '\n' +
      '*New Deadline* - MUST ORDER BY DECEMBER 15TH',
    teamEventYear: 2023,
    teamEventLocation: '',
    teamEventDivisions: ['8UYellow','8UBlue','10UYellow','10UBlue','12UYellow','12UBlue','14UYellow','14UBlue'],
    seasonYear: '2023-2024',
    paypalButtonId: 'DUMU4ABJL9BG6',
    paypalButtonIdDev: '',
    optionsTitle: 'Picture Fee',
    quantityLimit: 9999,
    quantityAvailable: 9998,
    retired: true,
    total: '$30',
    totalNum: 30,
    totalNumString: '30',
    playerName2Required: false,
    playerName3Required: false,
    playerNumberRequired: true,
    expireDateFull: '12/15/2023 11:59:00 pm',
    expireDateParsed: 1702684740000,
  },
  {
    teamEventId: 'teampictures23242',
    teamEventName: 'Team Pictures 2023-2024 2 TEST',
    teamEventDates: ['11-30-2023'],
    teamEventDatesFull: 'November 30th, 2023',
    teamEventDescription: 'TEST Includes all individual pictures taken, as well as the team picture. \n' +
      '\n' +
      'All release will be provided so that they can be printed anywhere you would like. \n' +
      '\n' +
      'Examples of locations include Costco, Sams club, canvaschamps.com. \n' +
      '\n' +
      'Please use email that you would like used to receive the pictures. \n' +
      '\n' +
      '*New Deadline* - MUST ORDER BY DECEMBER 15TH',
    teamEventYear: 2024,
    teamEventLocation: '',
    teamEventDivisions: ['8UYellow','8UBlue','10UYellow','10UBlue','12UYellow','12UBlue','14UYellow','14UBlue'],
    seasonYear: '2024-2025',
    paypalButtonId: '3PRP9BRGES2ZQ',
    paypalButtonIdDev: '',
    optionsTitle: 'Picture Fee',
    quantityLimit: 9999,
    quantityAvailable: 9998,
    retired: false,
    total: '$1',
    totalNum: 1,
    totalNumString: '1',
    playerName2Required: false,
    playerName3Required: false,
    playerNumberRequired: true,
    expireDateFull: '01/15/2024 11:59:00 pm',
    expireDateParsed: 1705363140000,
  },
  {
    teamEventId: 'mitestraveldeposit2024',
    teamEventName: 'Mites Travel Deposit',
    teamEventDates: ['06-01-2024'],
    teamEventDatesFull: 'Various Dates',
    teamEventDescription: 'Mites (8U) Travel Deposit',
    teamEventYear: 2024,
    teamEventLocation: 'Various Locations',
    teamEventDivisions: ['8U'],
    seasonYear: '2024-2025',
    paypalButtonId: '46NP6ZZRPDXR4',
    paypalButtonIdDev: '',
    optionsTitle: 'Deposit',
    quantityLimit: 9999,
    quantityAvailable: 9998,
    retired: true,
    total: '$207',
    totalNum: 207,
    totalNumString: '207',
    playerName2Required: false,
    playerName3Required: false,
  },
];

export default teamEventVarArray;
